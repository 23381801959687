import { useState } from 'react';
import Axios from 'axios';

const ajax = Axios.create({
  //baseURL: 'http://profound.able.cool/wp-json/wp/v2/',
  baseURL: 'http://cms.profound.tw/wp-json/wp/v2/',
  auth: {
    username: 'contact_us',
    password: 'I)kMtsH56N(wU6HJlJfMoU$u',
  },
});

export type DataType = {
  title: string;
  fields: {
    your_name: string;
    your_location: string;
    your_clinic: string;
    your_sex?: string;
    your_cons?: (string | undefined)[];
    your_mobile: string;
    your_mail?: string;
    your_desc?: string;
  };
};

type Mutation = (
  mutationData: DataType,
) => Promise<{
  status?: number;
  statusText?: string;
  error?: string;
}>;

type Response = [isSubmitting: boolean, mutate: Mutation];

export const useContactMutation = (): Response => {
  const [stateLoading, setStateLoading] = useState(false);

  const mutate = async (mutationData: DataType) => {
    setStateLoading(true);

    try {
      const { status, statusText } = await ajax.post(
        'contact_uss',
        mutationData,
      );
      return { status, statusText };
    } catch (error) {
      return { error: error.response.statusText };
    } finally {
      setStateLoading(false);
    }
  };

  return [stateLoading, mutate];
};
