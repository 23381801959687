import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { FluidImage } from '../types';

type QueryResponse = {
  source: FluidImage;
};

type Contact = {
  image: FluidObject;
};

export const useContactQuery = (): Contact => {
  const { source } = useStaticQuery<QueryResponse>(graphql`
    query ContactQuery {
      source: file(relativePath: { eq: "contact-woman.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 433, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return { image: source.childImageSharp.fluid };
};
