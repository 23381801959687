import React, { useState } from 'react';
import { Box, Button, Flex, Heading } from 'rebass/styled-components';
import styled from 'styled-components/macro';
import { Input, Textarea, Select } from '@rebass/forms/styled-components';
import { Tiles } from '@rebass/layout';
import _get from 'lodash/get';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import { useContactMutation, DataType } from '../mutations/useContactMutation';
import { useContactQuery } from '../queries/useContactQuery';
import Layout from '../components/Layout';
import { PageHeading, FormLabel, FormChoice } from '../components';
import { useRetailsQuery } from '../queries/useRetailsQuery';
import {graphql} from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';

const Contact = () => {
  const { image } = useContactQuery();
  const [stateName, setStateName] = useState('');
  const [stateSex, setStateSex] = useState('');
  const [stateLocation, setStateLocation] = useState('1');
  const [stateClinic, setStateClinic] = useState('');
  const [stateCons, setStateCons] = useState({
    '1': false,
    '2': false,
    '3': false,
    '4': false,
  });
  const [stateMobile, setStateMobile] = useState('');
  const [stateMail, setStateMail] = useState('');
  const [stateDesc, setStateDesc] = useState('');
  const [stateModalOpen, setStateModalOpen] = useState(false);
  const [isSubmitting, mutate] = useContactMutation();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setStateModalOpen(true);

    const data: DataType = {
      title: stateName,
      fields: {
        your_name: stateName,
        your_sex: stateSex,
        your_location: stateLocation,
        your_clinic: stateClinic,
        your_mobile: stateMobile,
        your_mail: stateMail,
        your_desc: stateDesc,
        your_cons: _map(stateCons, (value, key) => {
          if (!value) return;
          return key;
        }),
      },
    };

    const { error } = await mutate(data);

    if (error) {
      alert(error);
      setStateModalOpen(false);
      return;
    }
  };

  const onModalConfirm = () => (window.location.href = '/');

  const clinics = useRetailsQuery(stateLocation);

  return (
    <Layout>
      <Box variant="container">
        <PageHeading subTitle="Book now">預約諮詢</PageHeading>
        <Flex flexDirection={['column', null, 'row']} pb={['80px', '150px']}>
          <Box
            width="100%"
            maxWidth={433}
            mr={32}
            sx={{ display: ['none', null, 'block'] }}
          >
            <Img fluid={image} />
          </Box>
          <Box as="form" onSubmit={onSubmit} disabled={isSubmitting}>
            <Tiles columns={[1, 2]}>
              <Control>
                <FormLabel required htmlFor="your_name">
                  預約姓名
                </FormLabel>
                <Input
                  required
                  id="your_name"
                  name="your_name"
                  value={stateName}
                  onChange={(e) => {
                    setStateName(e.target.value);
                  }}
                />
              </Control>
              <Control>
                <FormLabel>性別</FormLabel>
                <Box mt="-8px">
                  {[
                    { value: 'm', text: '先生' },
                    { value: 'f', text: '小姐' },
                  ].map(({ value, text }) => (
                    <FormChoice
                      key={value}
                      type="radio"
                      onChange={(e) => {
                        setStateSex(e.target.value);
                      }}
                      name="your_sex"
                      value={value}
                      checked={stateSex === value}
                    >
                      {text}
                    </FormChoice>
                  ))}
                </Box>
              </Control>
              <Control>
                <FormLabel required htmlFor="your_location">
                  預約地區
                </FormLabel>
                <Flex
                  flexDirection="row"
                >
                  <Select
                    required
                    width={65}
                    onChange={(e) => setStateLocation(e.target.value)}
                    value={stateLocation}
                    id="your_location"
                    name="your_location"
                  >
                    {[
                      { value: '1', text: '北', },
                      { value: '2', text: '中', },
                      { value: '3', text: '南', },
                    ].map(({ value, text }) => (
                      <option key={value} value={value}>
                        {text}
                      </option>
                    ))}
                  </Select>
                  <Select
                    required
                    width="auto"
                    ml={8}
                    onChange={(e) => setStateClinic(e.target.value)}
                    value={stateClinic}
                    id="your_clinic"
                    name="your_clinic"
                  >
                    {clinics.map(({name}) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </Control>
              <Control>
                <FormLabel>諮詢項目</FormLabel>
                <Box mt="-8px">
                  {[
                    { value: '1', text: '皺紋' },
                    { value: '2', text: '鬆弛' },
                    { value: '3', text: '橘皮' },
                    { value: '4', text: '妊娠紋' },
                  ].map(({ value, text }) => (
                    <FormChoice
                      key={value}
                      type="checkbox"
                      onChange={(e) => {
                        setStateCons({
                          ...stateCons,
                          [value]: e.target.checked,
                        });
                      }}
                      checked={_get(stateCons, value)}
                      name={`your_cons[${value}]`}
                    >
                      {text}
                    </FormChoice>
                  ))}
                </Box>
              </Control>
              <Control>
                <FormLabel required htmlFor="your_mobile">
                  行動電話
                </FormLabel>
                <Input
                  required
                  type="tel"
                  id="your_mobile"
                  name="your_mobile"
                  value={stateMobile}
                  onChange={(e) => setStateMobile(e.target.value)}
                />
              </Control>
              <Control>
                <FormLabel htmlFor="your_mail">電子信箱</FormLabel>
                <Input
                  value={stateMail}
                  onChange={(e) => setStateMail(e.target.value)}
                  type="email"
                  name="your_mail"
                  id="your_mail"
                />
              </Control>
            </Tiles>
            <Control>
              <FormLabel htmlFor="your_desc">其他說明</FormLabel>
              <Textarea
                id="your_desc"
                name="your_desc"
                value={stateDesc}
                height="134px"
                onChange={(e) => setStateDesc(e.target.value)}
              />
            </Control>
            <Box
              mt={['48px', '33px']}
              sx={{
                textAlign: ['center', 'right'],
              }}
            >
              <Button type="submit" variant="outline" disabled={isSubmitting} backgroundColor="#FFFFF9">
                預約
              </Button>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        bg="rgba(255,255,249,0.6)"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: stateModalOpen ? 'modal' : '-1',
          visibility: stateModalOpen ? 'visible' : 'hidden',
          opacity: stateModalOpen ? 1 : 0,
          transition: 'all .3s ease-in-out',
        }}
      >
        {isSubmitting ? (
          <Heading fontFamily="serif" fontSize="18px">
            預約中...
          </Heading>
        ) : (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bg="background.white"
            py="80px"
            width={['80%', '385px']}
            sx={{
              boxShadow: '4px 4px 5px rgba(0,0,0,.4)',
              borderRadius: '4px',
            }}
          >
            <Heading mb="36px" fontFamily="serif" fontSize="18px">
              預約成功！
            </Heading>
            <Button variant="outline" onClick={onModalConfirm}>
              回首頁
            </Button>
          </Flex>
        )}
      </Flex>
    </Layout>
  );
};

const Control = styled(Box).attrs({
  mb: '24px',
})``;

export default Contact;
